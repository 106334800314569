<h1 class="text-title-4 mb-8">{{ 'rogersShaw.mainHeader' | translate }}</h1>
<p class="mb-24 text-body-lg"></p>
<div class="mb-16">
    <div class="text-center">
        <button ds-button
                type="submit"
                #submitLogin
                role="button"
                class="w-100 mb-16"
                (click)="onRogersSignInClick()">
          <span *ngIf="!isRogersSelected">{{'rogersShaw.singInRogers' | translate}}</span>
          <eas-loader class="eas-loader" *ngIf="loginInProgress && isRogersSelected"></eas-loader>
        </button>
      </div>

      <div class="text-center">
        <button ds-button
                type="submit"
                #submitLogin
                role="button"
                class="w-100 mb-16"
                (click)="onShawSignInClick()">
          <span *ngIf="!isShawSelected">{{'rogersShaw.singInShaw' | translate}}</span>
          <eas-loader class="eas-loader" *ngIf="loginInProgress && isShawSelected"></eas-loader>
        </button>
      </div>
</div>

<!-- <div [class]="recaptchaClass" (outsideClick)="outsideClickCapture()">
  <re-captcha
    *ngIf="useRecaptchaV3"
    #captchaRefV3="reCaptcha"
    [siteKey]="reCaptchaV3SiteKey"
    size="invisible"
    [badge]="recaptchaBadge"
    [actionName]="'username'"
    (resolved)="$event && processRecaptcha($event)"
  ></re-captcha>
  <re-captcha
    *ngIf="!useRecaptchaV3"
    #captchaRef="reCaptcha"
    [siteKey]="reCaptchaSiteKey"
    size="invisible"
    [badge]="recaptchaBadge"
    (resolved)="$event && processRecaptcha($event)">
  </re-captcha>
 </div> -->