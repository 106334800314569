<div>
  <form [formGroup]="changeUsernameForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <ng-container ssTrackEvent [event]="track.changeUsernameInputPageview"></ng-container>
    <h1 class="text-title-4 mb-8">
      {{ 'rogers.changeUsername.changeUsernameForm.header' | translate }}
    </h1>
    <p class="text-body-lg mb-8">
      {{ changeUsernameDesc | translate }}
    </p>

    <div class="mb-16" *ngIf="error === 'tooManyAttemptsOrExpired'">
      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorMessage()"
        role="alert"
        ssTrackError
        [error]="track.changeUsernameInputErrors">
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="error-outlined"
              color="error"
              [ariaHidden]="false"
              [attr.title]="'rogers.login.accessibility.error' | translate"
              caption="Attention">
            </ds-icon>
            <div>
              <p damTranslationWithRouting
                class="text-body mb-0 mw-content">
                {{ 'rogers.verifySms.error.tooManyAttemptsOrExpired' | translate }}
              </p>
            </div>
          </div>
        </div>
      </ds-alert>
    </div>

    <div class="mb-16" *ngIf="errorCode!='' ">
      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorMessage()"
        role="alert"
        ssTrackError
        [error]="track.changeUsernameInputErrors">
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="error-outlined"
              color="error"
              [ariaHidden]="false"
              [attr.title]="'rogers.login.accessibility.error' | translate"
              caption="Attention">
            </ds-icon>
            <div>
              <p class="text-body mb-0 mw-content">
                {{ errorCode | translate }}
              </p>
            </div>
          </div>
        </div>
      </ds-alert>
    </div>

  <ds-alert
    variant="error"
    *ngIf="!!apiErrorTranslationKey"
    role="alert"
    ssTrackError
    [error]="track.changeUsernameInputErrors">
    <div class="d-flex flex-column flex-md-row">
       <div class="flex-grow-1 d-flex">
          <ds-icon
             class="mr-8"
             name="error-outlined"
             color="error"
             [ariaHidden]="false"
             caption="Attention"
             ></ds-icon>
          <p
             class="text-body mb-0 mw-content"
             [innerHTML]="apiErrorTranslationKey | translate"
             ></p>
       </div>
    </div>
  </ds-alert>
  <ds-alert
    variant="error"
    *ngIf="!!apiErrorTranslationKey"
    role="alert"
    ssTrackError
    [error]="track.changeUsernameInputErrors">
    <div class="d-flex flex-column flex-md-row">
       <div class="flex-grow-1 d-flex">
          <ds-icon
             class="mr-8"
             name="error-outlined"
             color="error"
             [ariaHidden]="false"
             caption="Attention"
             ></ds-icon>
          <p
             class="text-body mb-0 mw-content"
             [innerHTML]="apiErrorTranslationKey | translate"
             ></p>
       </div>
    </div>
  </ds-alert>
  <div class="row">
    <div class="col-12 mt-16 mb-16">
       <ds-form-field>
          <ds-input-label>{{
             'rogers.changeUsername.changeUsernameForm.username' | translate
             }}
          </ds-input-label>
          <input
          dsInput
          type="email"
          [attr.autocorrect]="'off'"
          spellcheck="false"
          autocapitalize="off"
          formControlName="email"
          (keydown.space)="$event.preventDefault()"
          (keydown.enter)="keyDownEnter($event)"
          [attr.aria-required]="true"
          [attr.aria-label]="
          'rogers.changeUsername.changeUsernameForm.attrUsername'| translate "
          [attr.title]="'rogers.changeUsername.changeUsernameForm.attrUsername'| translate "
          #emailInput
          />
          <ds-error
          [errorMessage]="
          'rogers.registration.registerByEmail.error.enterEmail' | translate"
          role="alert"
          *ngIf="
          formSubmitted &&
          changeUsernameForm.invalid &&
          changeUsernameForm.get('email').errors.required
          "
          class="mt-8"
          ssTrackError
          [error]="track.changeUsernameInputFormError">
        </ds-error>
          <ds-error
          errorMessage="{{ errorCode | translate }}"
          alt="Attention"
          role="alert"
          *ngIf="formSubmitted && errorCode!='' "
          class="mt-8"
          ssTrackError
          [error]="track.changeUsernameInputFormError">
        </ds-error>

          <ds-error
          [errorMessage]="
          'rogers.changeUsername.changeUsernameForm.validEmail' | translate"
          *ngIf="
          formSubmitted &&
          changeUsernameForm.invalid &&
          changeUsernameForm.get('email').errors.pattern"
          role="alert"
          class="mt-8"
          ssTrackError
          [error]="track.changeUsernameInputFormError"
          ></ds-error>

       </ds-form-field>
    </div>
  </div>
  <div class="mb-16">
     <!-- <p class="text-body mb-0 mw-content">{{ 'rogers.changeUsername.changeUsernameForm.middleText' | translate }}</p> -->
  </div>
  <div>
    <button
    type="submit"
    ds-button
    class="w-100 mb-16"
    title="{{ 'rogers.changeUsername.changeUsernameForm.continue' | translate }}"
    #continueBtn
    >
    <span *ngIf="!changeUsernameInProgress">{{ 'common.continue' | translate }}</span>
    <eas-loader class="eas-loader" *ngIf="changeUsernameInProgress"></eas-loader>
    </button>

    <!-- <button ds-button [variant]="'tertiary'" style="margin-left: 20px;"
    title="{{ 'rogers.setPassword.recovery.changePasswordCancel' | translate }}"
      (click)="onChangeUsernameSkip($event)">
     {{ 'common.cancel' | translate }}
     </button> -->
  </div>
  </form>
</div>
