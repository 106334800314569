
<p class="mb-24 text-body-lg" translate></p>
<div class="mb-16">
  <form [formGroup]="securityQuestionForm">

    <div  class="text-normal" style="font-weight: bold">
      Security Question
    </div>
    <div class="text-normal mt-16">
      {{  securityQuestion }}
    </div>
    
      <div class="mt-16 mb-16">
        <div  class="text-normal" style="font-weight: bold">
         Answer
        </div>
        
        <div class="mb-16" *ngIf="errorCode">
          <ds-alert
            variant="error"
            [closeLabel]="'rogers.login.popOverClose' | translate"
            (closeClick)="resetErrorMessage()"
            role="alert">
            <div class="d-flex flex-column flex-md-row">
              <div class="flex-grow-1 d-flex">
                <ds-icon
                  class="mr-8"
                  name="error-outlined"
                  color="error"
                  [ariaHidden]="false"
                  [attr.title]="'rogers.login.accessibility.error' | translate"
                  caption="Attention">
                </ds-icon>
                <div>
                <p>{{ 'yahoo.security.errors.tryAgain' | translate }}</p>
                </div>
              </div>
            </div>
          </ds-alert>
        </div>

        
        <ds-form-field class="mt-16 mb-16">
            <ds-input-label>Answer</ds-input-label>
           <input
           dsInput
           type="email"
           [attr.autocorrect]="'off'"
           spellcheck="false"
           autocapitalize="off"
           formControlName="answer"
           (keydown.space)="$event.preventDefault()"
           [attr.aria-required]="true"
           />
        </ds-form-field>
     </div>

      <div style="margin-top: 30px;">

      </div>

      <div class="d-flex w-50">
        <button ds-button (click)="onSubmit()"
        type="submit"
        role="button"
        class="w-100">
        <span >Submit</span>
      </button>
    
        <button ds-button [variant]="'tertiary'"  (click)="onCancel()"
        style="margin-left: 20px;"> 
           {{ 'common.cancel' | translate }}
        </button>
      </div>
</form>
</div>


