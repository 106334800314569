import { Component, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HeaderStateService } from '../shared/header-state.service';
import { ReCaptchaBadge, RecaptchaComponent, RecaptchaLoaderService } from '@rogers/ng-recaptcha';
import { LoadRecaptchaSitekeyService } from '../shared/load-recaptcha-site-key-service';
import { delay, filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-ignite-tv-account-selection',
  templateUrl: './ignite-tv-account-selection.component.html',
  styleUrls: ['./ignite-tv-account-selection.component.scss']
})
export class IgniteTvAccountSelectionComponent implements OnInit {

  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;
  @ViewChild('captchaRefV3') captchaRefV3: RecaptchaComponent;

  // reCaptcha
  recaptcha: string;
  recaptchaBadge: ReCaptchaBadge = 'bottomright';
  recaptchaClass = 'recaptcha-non-ie';
  reCaptchaSiteKey: string;
  reCaptchaV3SiteKey: string;
  useRecaptchaV3 = true;
  blockRecaptcha = false;
  recaptchaTriggered = false;
  reCaptchaVersion = 'V3';
  loginInProgress = false;
  isRogersSelected = false;
  isShawSelected = false;

  onRogersSignInSubmit: (payload: object) => void;
  onShawSignInSubmit: (payload: object) => void;

  constructor(private readonly cookieService: CookieService,
              private readonly headerStateService: HeaderStateService,
              private readonly loadRecaptchaSiteKey: LoadRecaptchaSitekeyService,
              private readonly recaptchaLoaderService: RecaptchaLoaderService){

    let client =  this.cookieService.get('client');
    client = client === 'ignitetv' ? 'rogers' : client;

    this.reCaptchaSiteKey = this.loadRecaptchaSiteKey.getKey('V2', client);
    this.reCaptchaV3SiteKey = this.loadRecaptchaSiteKey.getKey('V3', client);
  }

  ngOnInit() {
    const useRecaptcha = this.cookieService.get('ignite-useRecaptchaV3');
    const version = this.cookieService.get('ignite-reCaptchaVersion');

    this.useRecaptchaV3 = useRecaptcha === 'false' ? false : true;
    this.reCaptchaVersion = version ? version : this.reCaptchaVersion;
  }

   onRogersSignInClick(){
    this.isRogersSelected = true;
    this.loginInProgress = true;
    this.headerStateService.setAppLogo('./idp/__default/assets/images/og-image.png');
    this.headerStateService.setBackToStart(true);
    this.cookieService.set('ignite-tv-selection', 'rogers', undefined, undefined, null, true, 'None');

    this.onShawSignInSubmit({
      userSelection: 'rogers'
    });
   }

   onShawSignInClick(){
    this.isShawSelected = true;
    this.loginInProgress = true;
    this.headerStateService.setAppLogo('./idp/__default/assets/images/rogers-shaw.png');
    this.headerStateService.setBackToStart(true);
    this.cookieService.set('ignite-tv-selection', 'shaw', undefined, undefined, null, true, 'None');

    this.onRogersSignInSubmit({
      userSelection: 'shaw'
    });
   }

    // recaptcha section
    processRecaptcha(recaptcha: string) {
      this.cookieService.delete('ignite-useRecaptchaV3');
      this.cookieService.delete('ignite-reCaptchaVersion');

      const selectedBrand = this.cookieService.get('ignite-tv-selection');
      this.isRogersSelected = selectedBrand === 'rogers' ? true : false;

      this.recaptcha = recaptcha;
      this.recaptchaTriggered = false;

      if (this.recaptcha){
        this.loginInProgress = true;
        if (this.isRogersSelected){
          this.onRogersSignInSubmit({
            userSelection: 'rogers',
            recaptachaToken: this.recaptcha,
            siteKey: this.reCaptchaVersion === 'V2' ? this.reCaptchaSiteKey : this.reCaptchaV3SiteKey,
            language: this.cookieService.get('lang'),
            version: this.reCaptchaVersion
          });
        }
        else{
          this.onShawSignInSubmit({
            userSelection: 'shaw',
            recaptachaToken: this.recaptcha,
            siteKey: this.reCaptchaVersion === 'V2' ? this.reCaptchaSiteKey : this.reCaptchaV3SiteKey,
            language: this.cookieService.get('lang'),
            version: this.reCaptchaVersion
          });
        }
      }
      else{
        this.triggerRecaptcha();
      }
    }

    outsideClickCapture() {
    }

    triggerRecaptcha() {
      this.recaptchaLoaderService.ready
        .pipe(
          filter(val => val !== null),
          take(1),
          map(val => !!val),
          delay(350)
        )
        .subscribe(recaptchaIsReady => {
          if (recaptchaIsReady) {
            if (!this.loginInProgress) {
              this.recaptchaTriggered = true;
              if (this.useRecaptchaV3 && !!this.captchaRefV3) {
                this.captchaRefV3.execute();
                this.reCaptchaVersion = 'V3';
              } else if (this.captchaRef) {
                this.captchaRef.execute();
                this.reCaptchaVersion = 'V2';
              }
            } else if (
              this.loginInProgress &&
              this.recaptchaTriggered &&
              !!this.captchaRef
            ) {
              this.captchaRef.execute();
              this.reCaptchaVersion = 'V2';
            }
          }
        });
    }

     resetRecaptcha() {
      if (!this.blockRecaptcha) {
        this.recaptcha = '';
        if (this.useRecaptchaV3 && this.captchaRefV3) {
          this.captchaRefV3.reset();
        } else if (!this.useRecaptchaV3 && this.captchaRef) {
          this.captchaRef.reset();
        }
      }
    }
}
