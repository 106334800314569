
<div class="verify-component">
    <h1 class="text-title-4 mb-8" role="heading">
      <img
        [src]="'idp/__default/assets/images/' + verifyType + '.png'"
        class="mr-16 mr-sm-8 verify-icon"
        alt=""
      />
      <span >{{
        'rogers.' + verifyType + '.mainHeader' | translate
      }}</span>
    </h1>
    <p
    class="text-body-lg mb-24"
    [innerHTML]="
      'rogers.' + verifyType + '.mainMessage'
        | translate
          : {
              value: optTargetDescription
            }">
        </p>

<form [formGroup]="verifyUserForm" autocomplete="off" (ngSubmit)="onSubmit()"
 preventSubmitDirective>
  <ng-container ssTrackEvent [event]="track.changeUsernameOtpPageview"></ng-container>

 <div class="mb-16 code-input-container">

  <div class="mb-16" *ngIf="(formSubmitted && maxOtpAttemptsTried) || incorrectCode  || otherError">
    <ds-alert
      variant="error"
      [closeLabel]="'rogers.login.popOverClose' | translate"
      (closeClick)="resetErrorMessage()"
      role="alert"
      ssTrackError
      [error]="track.changeUsernameOtpErrors"
    >
      <div class="d-flex flex-column flex-md-row">
        <div class="flex-grow-1 d-flex">
          <ds-icon
            class="mr-8"
            name="error-outlined"
            color="error"
            [ariaHidden]="false"
            [attr.title]="'rogers.login.accessibility.error' | translate"
            caption="Attention">
          </ds-icon>
          <div>
            <p *ngIf='maxOtpAttemptsTried'
              damTranslationWithRouting
              class="text-body mb-0 mw-content">
              {{ 'rogers.verifySms.error.maxOtpAttemptsTried' | translate }}
            </p>
            <p *ngIf='incorrectCode'
              damTranslationWithRouting
              class="text-body mb-0 mw-content">
              {{ 'rogers.verifySms.error.incorrectCode' | translate }}
            </p>

            <p *ngIf='otherError'
              damTranslationWithRouting
              class="text-body mb-0 mw-content">
              {{ 'rogers.verifySms.error.system' | translate }}
            </p>
          </div>
        </div>
      </div>
    </ds-alert>
  </div>

    <div class="mb-16 code-input-container"
    [ngClass]="{'otp-invalid': formSubmitted && !otpFieldIsInvalid}">

    <ds-code-input
      [a11yLabel]="'rogers.verifyEmail.verificationInput' | translate"
      [focusPreviousAfterClearing]="false"
      [length]="6"
      #codeInputRef
      (codeChanged)="onCodeChanged()"
      (codeCompleted)="onCodeCompleted($event)"
      (keydown.enter)="keyDownEnter($event)">
    </ds-code-input>

    <ds-error
    [errorMessage]="'rogers.verifySms.error.enterCode' | translate"
    *ngIf="formSubmitted && !otpFieldIsInvalid"
    role="alert"
    ssTrackError
    [error]="track.changeUsernameOtpFormError">
  </ds-error>

  </div>

    <div class="text-body-md mb-32 mt-16 flex-wrap" [ngClass]="brand == 'media' ? '' : 'd-flex'">
      <p
      class="text-body-md pt-12 mr-4"
      [innerHTML]="'rogers.' + verifyType + '.didntGet' | translate"></p>
      <ng-container
      counter
      [counter]="counterInitialValue"
      (value)="onCounterChange($event)">
      <ng-container *ngIf="resendCodeClick" ssTrackEvent [event]="track.changeUsernameOtpResendCode"></ng-container>
      <div>
        <button
        ds-button
        variant="tertiary"
        (click)="onResendCode()"
        [disabled]="resendDisabled"
        [attr.aria-disabled]="resendDisabled"
        [attr.aria-label]="
          'rogers.' + verifyType + '.accessibility.resendCode' | translate
        "
        [attr.title]="
          'rogers.' + verifyType + '.accessibility.resendCode' | translate
        "
      >
        <span
          class="text-body-lg"
          [innerHTML]="'common.resendCode' | translate"
        >
        </span>
        <ds-icon *ngIf="counter === 0" name="chevron-right"></ds-icon>
        </button>

        <span
          *ngIf="counter > 0"
          class="ml-8 text-body-lg otp-timer"
          [innerHTML]="'common.timer' | translate: { counter: counter }">
        </span>
      </div>

      <div>
         <b style="margin-right:10px;">{{ 'common.or' | translate }}</b>
          <a
          ds-link
          variant="cta"
          class="mb-16 ds-color-link"
          (click)="onChangeEmail()"
          [attr.title]="'common.accessibility.changeEmail' | translate">
          {{ 'common.changeEmail' | translate }}</a>
      </div>

    </ng-container>

  </div>

  <button
  ds-button
  class="w-100 mb-16"
  type="submit"
  #continueBtn
  [attr.title]="'common.accessibility.continue' | translate">
  <span>{{'common.continue' | translate}}</span>
  </button>
  </div>

</form>

<div *ngIf="showHelp">
<div class="d-flex ds-bgcolor-fog verify-email-footer">
    <div class="verify-email-footer__content d-flex flex-wrap text-body-lg">
     <div  class="text-body-lg pt-8  mr-4" style="margin-top: 2px;">
       {{ 'rogers.verifyEmail.footerText' | translate }}
      </div>

   <span class="text-body-lg  text-semi" style="margin-top: 10px;margin-left: 10px;">
    <a href="#" onclick="
    event.preventDefault();
    window.postMessage({
        message: 'VA-OPEN',
        messageData: { buttonId: '999991001006'}
    }, '/');">Live Chat  ></a></span>


   </div>
</div>
</div>

</div>
