<h1 class="text-title-4 mb-8" translate>rogers.setPassword.recovery.changePassword</h1>
<p class="text-body-lg mb-8" translate>rogers.setPassword.recovery.changePasswordTitle</p>


<div class="mb-16" *ngIf="passwordFormErrorAlert">
  <ds-alert
    variant="error"
    [closeLabel]="'rogers.login.popOverClose' | translate"
    (closeClick)="resetErrorCode()"
    role="alert"
    ssTrackError
    [error]="track.changePasswordInputErrors"
  >
    <div class="d-flex flex-column flex-md-row">
      <div class="flex-grow-1 d-flex">
        <ds-icon
          class="mr-8"
          name="error-outlined"
          color="error"
          [ariaHidden]="false"
          caption="Attention"
          [attr.title]="'rogers.login.accessibility.error' | translate"
        ></ds-icon>
        <div>
          <p
            class="text-body mb-0 mw-content"
            [innerHTML]="'rogers.errors.UE033'  | translate"
          ></p>
        </div>
      </div>
    </div>
  </ds-alert>
</div>

<form
  [formGroup]="currentPasswordForm"
  (ngSubmit)="validateAndSubmit()"
  #currentPasswordFormRef
  id="currentPasswordForm"
  method="POST"
  novalidate>
  <ng-container ssTrackEvent [event]="track.changePasswordInputPageview"></ng-container>
  <div>
    <ds-form-field>
      <ds-input-label>{{ 'common.changeCurrentPassword' | translate }}</ds-input-label>
      <input
        [showPasswordToggleButton]="false"
        [attr.type]="showNewPassword ? 'password' : 'text'"
        type="password"
        dsInput
        [(ngModel)]="code"
        #passwordInput
        id="currentPassword"
        class="round-input"
        formControlName="currentPassword"
        name="currentPassword"
        spellcheck="false"
        [attr.autocorrect]="'off'"
        autocomplete="current-password"
        (keydown.enter)="keyDownEnter($event)"
        (keydown.space)="$event.preventDefault()"
        [attr.title]="'common.changePasswordAttr' | translate"
        [attr.aria-required]="true"
        [attr.aria-invalid]="false"
        [attr.aria-label]="'rogers.login.accessibility.showPassword' | translate"/>

      <button
        dsSuffix
        ds-button
        type="button"
        variant="icon"
        [attr.aria-pressed]="!showNewPassword"
        (click)="showNewPassword = !showNewPassword"
        title="{{
            showNewPassword
              ? ('common.showPasword' | translate)
              : ('common.hidePassword' | translate)
          }}"
      >
        <ds-icon [name]="showNewPassword ? 'hide' : 'show'"></ds-icon>
      </button>

      <ds-error *ngIf="passwordError"
      errorMessage="{{ 'rogers.errors.UE034' | translate }}"
      alt="Attention"
      role="alert"
      ssTrackError
      [error]="track.changePasswordInputFormError">
    </ds-error>

    </ds-form-field>

  </div>
  <div class="mb-32 "></div>

  <div class="d-flex w-50">
    <button
      ds-button
      role="button"
      class="w-100 mb-16"
      type="submit"
      #continueBtn
      title="{{ 'rogers.setPassword.recovery.changePasswordContinue' | translate }}">
      <span *ngIf="!isInProgress">{{'common.continue' | translate}}</span>
      <eas-loader class="eas-loader" *ngIf="isInProgress"></eas-loader>
    </button>

    <button ds-button [variant]="'tertiary'" style="margin-left: 20px;"
       title="{{ 'rogers.setPassword.recovery.changePasswordCancel' | translate }}"
      (click)="onChangePasswordSkip($event)"> {{ 'common.cancel' | translate }}
    </button>
  </div>
</form>
