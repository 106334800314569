import {UsernameFormSessionComponent} from './usernameFormSession.component';
import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';

class UsernameFormSession implements com.ts.mobile.sdk.UIFormSession{
  clientContext: object;
  actionContext: object;
  formId: string;
  payload: object;

  submitHandler: any;
  submitBlock: (payload: object) => void;
  // currentHandler: (response: com.ts.mobile.sdk.InputOrControlResponse<any>) => void;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  usernameFormSessionCompRef: ComponentRef<UsernameFormSessionComponent>;

  constructor(formId: string, payload: object) {
      console.log('Inside usernameFormTs constructor');
      this.formId = formId;
      this.payload = payload;
  }
  startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
    console.log('Form session started: ' + this.formId);
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.resolver = (clientContext as any).resolver;
    this.viewContainerRef = (clientContext as any).viewContainerRef;

  }
  endSession(): void {
    console.log('Form session ended: ' + this.formId);
    // console.log('User locked');
    // setTimeout(() => this.usernameFormSessionCompRef.instance.setErrorCode(), 100)
  }
  promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
    console.log('Form session Input: ' + this.formId);
    const self = this;
    self.viewContainerRef.clear();

    const factory: ComponentFactory<UsernameFormSessionComponent> = self.resolver.resolveComponentFactory(UsernameFormSessionComponent);
    self.usernameFormSessionCompRef = self.viewContainerRef.createComponent(factory);
    self.usernameFormSessionCompRef.instance.onSubmitUserId = self.onSubmitUserData.bind(self);
    self.usernameFormSessionCompRef.instance.onForgotUserEmailSubmit = self.onForgotUsernameSubmit.bind(self);
    self.usernameFormSessionCompRef.instance.onRegisterClick = self.onRegisterClick.bind(self);
    self.usernameFormSessionCompRef.instance.onForgotUsernameAccountClick = self.onForgotUsernameAccountClick.bind(self);
    self.usernameFormSessionCompRef.instance.onVerifyUserEmailSubmit = self.onRegPendingVerifyEmail.bind(self);

    console.log('usernameFormTs constructor payload:', this.payload);

    const journeyPayload = this.payload as JourneyPayload;
    console.log('payload actions using interface', journeyPayload.actions);

    const action = journeyPayload.actions[0] as ActionsInPayload;
    console.log('action flow using interface', action.flow);

    self.usernameFormSessionCompRef.instance.flowFromPayload = 'login';

    const loginHintEmail = action.loginhint === null ? '' : action.loginhint;
    this.clientContext['cookieService'].set('login-hint-email', loginHintEmail, undefined, undefined, null, true, 'None');

    return new Promise((resolve, reject) => {
      self.submitBlock = (payload: object) => {
        try {
          resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
          console.log('usernameFormTs createFormInputSubmissionRequest SUCCESS payload: ', payload);
          self.submitBlock = null; // assign null to prevent using the same promise more then once
        } catch (e) {
          console.log('usernameFormTs createFormInputSubmissionRequest ERROR payload: ', payload);
          console.error(e);
        }
      };
    });
  }
  onContinue(payload: object): void {
    console.log('usernameFormTs onContinue for ' + this.formId + ' payload:', payload);
  }
  onError(payload: object): void {
    const errorPayload = payload as JourneyPayload;
    const flow = this.clientContext['cookieService'].get('flow');
    let userId = this.clientContext['cookieService'].get('userEmail');

    if (flow === 'username' && (errorPayload.code === 'UE01' || errorPayload.code === 'UE05')){
      this.usernameFormSessionCompRef.instance.triggerRiskAction('failed-forgot-username', userId);
    }
    else{
      this.usernameFormSessionCompRef.instance.triggerRiskAction('failed-username', userId);
    }

    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];

    console.log('usernameFormTs onError for ' + this.formId + ' payload:', payload);

    if (errorPayload.code === 'BL02'){
      let appendChar = '?';
      if (url.includes('?'))
      {
         appendChar = '&';
      }
      window.location.href = url + appendChar + 'error=' + errorPayload.code + '&error_description=suspended' 
      + '&lang=' + this.clientContext['cookieService'].get('lang')
      + '&state=' + state;
    }
    else if (errorPayload.code === 'RC01'){
      const url = this.clientContext['redirect_uri'];
      const state = this.clientContext['state'];
      const errorCode  = 'rc01';
      let appendChar = '?';
      if (url.includes('?'))
      {
          appendChar = '&';
      }
      window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=rc01'
      + '&lang=' + this.clientContext['cookieService'].get('lang')
      + '&state=' + state;

    }
    else if (errorPayload.code === 'FB01'){
      this.processRecaptchaFailure();
    }
    else {
      this.clientContext['cookieService'].set('loginStoreErrorCode', 
      errorPayload.code ? errorPayload.code : null, undefined, undefined, null, true, 'None');
    }
  }

  /** Non UIFormSession interface Functions */
  private onSubmitUserData(payload: object) {
    this.clientContext['cookieService'].delete('flow');
    this.clientContext['cookieService'].set('userEmail', payload['username'], undefined, undefined, null, true, 'None');

    this.clientContext['cookieService'].set('userEmail-username', payload['username'], undefined, undefined, null, true, 'None');

    console.log('usernameFormTs onSubmitUserData for ' + this.formId + ' payload:', payload);
    this.clientContext['cookieService'].set('rememberMe', payload['rememberme'], undefined, undefined, null, true, 'None');
    const data = payload;
    this.submitBlock(data);
  }

  private onForgotUsernameSubmit(payload: object) {
    this.clientContext['cookieService'].set('flow', 'username', undefined, undefined, null, true, 'None');
    this.clientContext['cookieService'].set('forgot-username', payload['username'], undefined, undefined, null, true, 'None');
    console.log('usernameFormTs on forgot submit username for payload:', payload);
    const data = payload;
    this.submitBlock(data);
  }

  private onRegPendingVerifyEmail(payload: object) {
    console.log('usernameFormTs on Reg pending verify user email  for payload:', payload);
    const data = payload;
    this.clientContext['cookieService'].delete('verifyEmail');
    this.submitBlock(data);
  }

  private onRegisterClick(brand: string){

    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'register';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=register'
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }

  private onForgotUsernameAccountClick(){
    const url = this.clientContext['redirect_uri'];
    const state = this.clientContext['state'];
    const errorCode  = 'forgotUsername';
    let appendChar = '?';
    if (url.includes('?'))
    {
       appendChar = '&';
    }
    window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=forgotUsername'
    + '&lang=' + this.clientContext['cookieService'].get('lang')
    + '&state=' + state;
  }

  private processRecaptchaFailure(){
    const self = this;
    this.clientContext['cookieService'].set('useRecaptchaV3', false, undefined, undefined, null, true, 'None');
    this.clientContext['cookieService'].set('reCaptchaVersion', 'V2', undefined, undefined, null, true, 'None');

    setTimeout(() => self.usernameFormSessionCompRef.instance.validateAndSubmit(), 10);
  }
}

interface JourneyPayload {
  actions: ActionsInPayload[];
  stat: string;
  code: string;
  flow: string;
  recaptchastatus: string;
  loginhint: string;
}

interface ActionsInPayload {
  [key: string]: any;
}

export default UsernameFormSession;
