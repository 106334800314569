import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserSessionRiskService } from '../shared/user-session-risk-service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-security-question',
  templateUrl: './security-question.component.html',
  styleUrls: ['./security-question.component.scss']
})
export class SecurityQuestionComponent implements OnInit {

  securityQuestion: null;
  securityQuestionForm: UntypedFormGroup;

  errorCode = '';

  onSecurityAnwerSubmit: (answer: string) => void;
  onCancelSubmit: () => void;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userSessionRiskService: UserSessionRiskService,
    private readonly cookieService: CookieService) { }

  ngOnInit(): void {
    this.securityQuestionForm = this.formBuilder.group({
      answer : ['', Validators.required]
    });

  }

  onSubmit(){
    const ansVal = this.securityQuestionForm.value.answer;
    let userName = this.cookieService.get('username');
    const userID = this.cookieService.get('userEmail');

    if (userName === '' || userName === null || userName === undefined){
      userName = userID;
    }

    this.triggerRiskAction('security-question', userName);
    this.onSecurityAnwerSubmit(ansVal);
  }

  onCancel(){
    let userName = this.cookieService.get('username');
    const userID = this.cookieService.get('userEmail');

    if (userName === '' || userName === null || userName === undefined){
      userName = userID;
    }
    this.triggerRiskAction('security-question-cancel', userName);
    this.onCancelSubmit();
  }

  setErrorCode(code){
    this.errorCode = code;
  }

  resetErrorMessage(){
    this.errorCode = '';
  }

  async triggerRiskAction(flow: string, userId: string) {
    await this.userSessionRiskService.triggerAction(flow, userId);
  }

}

