import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from "@angular/core";
import { ChangeUsernameComponent } from "./change-username.component";


class ChangeUsernameFormSession implements com.ts.mobile.sdk.UIFormSession{
    clientContext: object;
    actionContext: object;
    formId: string;
    payload: object;

    submitHandler: any;
    submitBlock: (payload: object) => void;
    resolver: ComponentFactoryResolver;
    viewContainerRef: ViewContainerRef;
    changeUsernameFormSessionCompRef: ComponentRef<ChangeUsernameComponent>;

    constructor(formId: string, payload: object) {
        console.log('Inside change username form constructor');
        this.formId = formId;
        this.payload = payload;
    }

    startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
        console.log('Change Username Form session started: ' + this.formId);
        this.clientContext = clientContext;
        this.actionContext = actionContext;
        this.resolver = (clientContext as any).resolver;
        this.viewContainerRef = (clientContext as any).viewContainerRef;
      }

      endSession(): void {
        console.log('Change Username Form session ended: ' + this.formId);
      }

      promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
        console.log('Change Uername Form session Input: ' + this.formId);
        const self = this;
        self.viewContainerRef.clear();
        const factory: ComponentFactory<ChangeUsernameComponent> = self.resolver.resolveComponentFactory(ChangeUsernameComponent);
        self.changeUsernameFormSessionCompRef = self.viewContainerRef.createComponent(factory);
        self.changeUsernameFormSessionCompRef.instance.onSubmitNewUsername = self.onSubmitNewUseruserName.bind(self);

        self.changeUsernameFormSessionCompRef.instance.onChangeUsernameSkipClick = self.onChangeUsernameSkipSubmit.bind(self);

        console.log('change usernameFormTs constructor payload:', this.payload);

        const journeyPayload = this.payload as JourneyPayload;
        console.log('change usernameFormTs payload actions using interface', journeyPayload.actions);

        const action = journeyPayload.actions[0] as ActionsInPayload;
        console.log('change username formTS action flow using interface', action.flow);

        self.changeUsernameFormSessionCompRef.instance.flowFromPayload = 'changeUsername';

        return new Promise((resolve, reject) => {
          self.submitBlock = (payload: object) => {
            try {
              resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
              console.log('change usernameFormTs createFormInputSubmissionRequest SUCCESS payload: ', payload);
              self.submitBlock = null;
            } catch (e) {
              console.log('change usernameFormTs createFormInputSubmissionRequest ERROR payload: ', payload);
              console.error(e);
            }
          };
        });
      }

      onContinue(payload: object): void {
        console.log('change usernameFormTs onContinue for ' + this.formId + ' payload:', payload);
      }

      onError(payload: object): void {
        const userEmail = this.clientContext['cookieService'].get('userEmail');

        this.changeUsernameFormSessionCompRef.instance.triggerRiskAction('failed-profile-change-username', userEmail);
        console.log('change usernameFormTs onError for ' + this.formId + ' payload:', payload);
        // const errorPayload = payload as JourneyPayload;

        if (payload['email'] === userEmail)
        {
          setTimeout(() => this.changeUsernameFormSessionCompRef.instance.setErrorCode('ECP4'), 100);
        }
        else if (payload['code'] === '0' && payload['stat'] === 'ok')
        {
          setTimeout(() => this.changeUsernameFormSessionCompRef.instance.setErrorCode('ECP3'), 100);
        }
        else
        {
          setTimeout(() => this.changeUsernameFormSessionCompRef.instance.setErrorCode(payload['code']), 100);
        }
      }

      private onSubmitNewUseruserName(payload: object) {
        this.clientContext['nextStep'] = 'changeUsernameFlow';
        console.log('change usernameFormTs onSubmitNewUseruserName for ' + this.formId + ' payload:', payload);
        const data = payload;
        this.submitBlock(data);
      }

      onChangeUsernameSkipSubmit = (code: string) => {
        const userEmail = this.clientContext['cookieService'].get('username');
        this.changeUsernameFormSessionCompRef.instance.triggerRiskAction('profile-change-username-skip', userEmail);
        const url = this.clientContext['redirect_uri'];
        const state = this.clientContext['state'];
        const errorCode  = 'cancel_username';
        let appendChar = '?';
        if (url.includes('?'))
        {
           appendChar = '&';
        }
        window.location.href = url + appendChar + 'error=' + errorCode + '&error_description=cancel_username' 
        + '&lang=' + this.clientContext['cookieService'].get('lang')
        + '&state=' + state;
      }
}

interface JourneyPayload {
    actions: ActionsInPayload[];
    stat: string;
    code: string;
    flow: string;
  }

interface ActionsInPayload {
    [key: string]: any;
  }

export default ChangeUsernameFormSession;
