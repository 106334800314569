import { Injectable } from "@angular/core";
import { NgxTsRiskidService } from "@transmitsecurity/ngx-ts-riskid";
import { ActionEventOptions } from "@transmitsecurity/ngx-ts-riskid/types/web_sdk";

@Injectable()
export class UserSessionRiskService {

constructor(private riskService: NgxTsRiskidService) {}

async onSessionEstablished(userIdentifier) {
  this.sha256(userIdentifier).then(async (hashed) => {
    await this.riskService.identify(hashed);
  });
    // Business logic here
  }

  // Logout or expire
  async onSessionRevoked() {
    // Business logic here
    this.riskService.unidentify();
    const actionResponse = await this.riskService.triggerAction(NgxTsRiskidService.ACTION_TYPES.LOGOUT);
    const actionToken = actionResponse.actionToken;
    // Add code here to send the action and the received actionToken to your backend
  }

   async triggerAction(flow: string, userId: string){
    const obj = {} as ActionEventOptions;

    if (userId !== '' && userId !== null && userId !== undefined){
      this.sha256(userId).then(async (hashedId) => {
        obj.claimedUserId = hashedId;

        try {
          await this.riskService.triggerAction(flow, obj);
        } catch (e) {
          console.log('Error while logging the DRS stats for the flow: ', flow);
        }
      });
    }
    else{
      try {
        await this.riskService.triggerAction(flow);
      } catch (e) {
        console.log('Error while logging the DRS stats for the flow: ', flow);
      }
    }
  }

  sha256(str) {
    // Get the string as arraybuffer.
    const buffer = new TextEncoder().encode(str);
    return crypto.subtle.digest('SHA-256', buffer).then((hash) => {
      return this.hex(hash);
    });
  }

  hex(buffer) {
    let digest = '';
    const view = new DataView(buffer);
    for (let i = 0; i < view.byteLength; i += 4) {
      // We use getUint32 to reduce the number of iterations (notice the `i += 4`)
      const value = view.getUint32(i);
      // toString(16) will transform the integer into the corresponding hex string
      // but will remove any initial "0"
      const stringValue = value.toString(16);
      // One Uint32 element is 4 bytes or 8 hex chars (it would also work with 4
      // chars for Uint16 and 2 chars for Uint8)
      const padding = '00000000';
      const paddedValue = (padding + stringValue).slice(-padding.length);
      digest += paddedValue;
    }

    return digest;
  }
}
