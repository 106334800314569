<div>
    <h1 class="text-title-4 mb-8">{{ 'rogers.otpDeviceList.header' | translate }}</h1>

    <div class="mb-16" *ngIf="error === 'tooManyAttemptsOrExpired'">
      <ds-alert
        variant="error"
        [closeLabel]="'rogers.login.popOverClose' | translate"
        (closeClick)="resetErrorMessage()"
        role="alert">
        <div class="d-flex flex-column flex-md-row">
          <div class="flex-grow-1 d-flex">
            <ds-icon
              class="mr-8"
              name="error-outlined"
              color="error"
              [ariaHidden]="false"
              [attr.title]="'rogers.login.accessibility.error' | translate"
              caption="Attention">
            </ds-icon>
            <div>
              <p damTranslationWithRouting
                class="text-body mb-0 mw-content">
                {{ 'rogers.verifySms.error.tooManyAttemptsOrExpired' | translate }}
              </p>
            </div>
          </div>
        </div>
      </ds-alert>
    </div>

    <p class="mb-24 text-body-lg">{{ 'rogers.otpDeviceList.description' | translate }}</p>
    <ng-container ssTrackEvent [event]="track.otpMethodSelection"></ng-container>
    <div *ngIf="targetsCount === 1 && !hideCtn && brand !== 'media'">
      <button
      ds-button disabled
      role="button"
      class="w-100 mb-16"
      title="{{ 'rogers.otpDeviceList.accessibility.sms' | translate }}"
      type="button">
        <span> {{ 'rogers.otpDeviceList.channels.sms' | translate }} : ***.***.****</span>
    </button>
    </div>

    <div *ngFor="let target of formattedTargets; let i = index">
      <div *ngIf="target.targetDescription === 'null';else showTarget">
        <button
        ds-button disabled
        role="button"
        class="w-100 mb-16"
        (click)="chooseOTPTarget(target.order)"
        title="{{ target.title | translate }}"
        type="button">
          <span> {{ target.text | translate }} : ***.***.****</span>
      </button>
    </div>

    <ng-template #showTarget>
      <button
      ds-button
      role="button"
      class="w-100 mb-16"
      (click)="chooseOTPTarget(target.order)"
      title="{{ target.title | translate }}"
      type="button">
      <span> {{ target.text | translate }} : {{target.targetDescription}}</span>
      </button>
    </ng-template>
    </div>

    <div *ngIf="targetsCount === 1 && !hideCtn && brand === 'media'">
      <button
      ds-button disabled
      role="button"
      class="w-100 mb-16"
      title="{{ 'rogers.otpDeviceList.accessibility.sms' | translate }}"
      type="button">
        <span> {{ 'rogers.otpDeviceList.channels.sms' | translate }} : ***.***.****</span>
    </button>
    </div>

    <div *ngIf ="!hideEmail">
      <button
      ds-button disabled
      role="button"
      class="w-100 mb-16"
      title="{{ 'rogers.otpDeviceList.accessibility.email' | translate }}"
      type="button">
        <span> {{ 'rogers.otpDeviceList.channels.email' | translate }} : **********</span>
    </button>
    </div>
</div>
