import { ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { stat } from 'fs';
import { IgniteTvAccountSelectionComponent } from './ignite-tv-account-selection.component';

class IgniteTvSelectionFormSession implements com.ts.mobile.sdk.UIFormSession{
  clientContext: object;
  actionContext: object;
  formId: string;
  payload: object;

  submitHandler: any;
  submitBlock: (payload: object) => void;
  resolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;
  igniteTvSelectionFormSessionComponentRef: ComponentRef<IgniteTvAccountSelectionComponent>;

  constructor(formId: string, payload: object) {
      console.log('Inside IgniteTvSelectionFormTs constructor');
      this.formId = formId;
      this.payload = payload;
  }
  startSession(clientContext: object | null, actionContext: com.ts.mobile.sdk.PolicyAction | null): void {
    console.log('IgniteTvSelectionFormTs session started: ' + this.formId);
    this.clientContext = clientContext;
    this.actionContext = actionContext;
    this.resolver = (clientContext as any).resolver;
    this.viewContainerRef = (clientContext as any).viewContainerRef;

  }
  endSession(): void {
    console.log('IgniteTvSelectionFormTs session ended: ' + this.formId);
    console.log('User locked');
  }
  promiseFormInput(): Promise<com.ts.mobile.sdk.FormInput> {
    console.log('IgniteTvSelectionFormTs session Input: ' + this.formId);
    const self = this;
    self.viewContainerRef.clear();

    const factory: ComponentFactory<IgniteTvAccountSelectionComponent> = 
    self.resolver.resolveComponentFactory(IgniteTvAccountSelectionComponent);
    self.igniteTvSelectionFormSessionComponentRef = self.viewContainerRef.createComponent(factory);

    self.igniteTvSelectionFormSessionComponentRef.instance.onRogersSignInSubmit = self.onRogersSignInSubmit.bind(self);
    self.igniteTvSelectionFormSessionComponentRef.instance.onShawSignInSubmit = self.onShawSignInSubmit.bind(self);

    console.log('IgniteTvSelectionFormTs constructor payload:', this.payload);

    const journeyPayload = this.payload as JourneyPayload;
    console.log('IgniteTvSelectionFormTs:payload actions using interface', journeyPayload.actions);


    return new Promise((resolve, reject) => {
      self.submitBlock = (payload: object) => {
        try {
          resolve(com.ts.mobile.sdk.FormInput.createFormInputSubmissionRequest(Object.assign(payload)));
          console.log('IgniteTvSelectionFormTs SUCCESS payload: ', payload);
          self.submitBlock = null;
        } catch (e) {
          console.log('IgniteTvSelectionFormTs ERROR payload: ', payload);
          console.error(e);
        }
      };
    });
  }
  onContinue(payload: object): void {
    console.log('IgniteTvSelectionFormTs onContinue for ' + this.formId + ' payload:', payload);
  }
  onError(payload: object): void {
    const errorPayload = payload as JourneyPayload;

    if (errorPayload.code === 'FB01'){
       this.processRecaptchaFailure();
    }
}
   /** Non UIFormSession interface Functions */
   private onRogersSignInSubmit(payload: object) {
    this.submitBlock(payload);
  }

  private onShawSignInSubmit(payload: object) {
    this.submitBlock(payload);
  }

  private processRecaptchaFailure(){
    const self = this;
    this.clientContext['cookieService'].set('ignite-useRecaptchaV3', false, undefined, undefined, null, true, 'None');
    this.clientContext['cookieService'].set('ignite-reCaptchaVersion', 'V2', undefined, undefined, null, true, 'None');

    setTimeout(() => self.igniteTvSelectionFormSessionComponentRef.instance.triggerRecaptcha(), 10);
  }
}

interface JourneyPayload {
  actions: ActionsInPayload[];
  stat: string;
  code: string;
  flow: string;
  recaptchastatus: string;
}

interface ActionsInPayload {
  [key: string]: any;
}

export default IgniteTvSelectionFormSession;