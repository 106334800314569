import { Injectable } from '@angular/core';
import { commonEnvironment } from 'src/environments/common-environment';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class LoadRecaptchaSitekeyService {
  constructor() {
  }
  getKey(version: string, client: string): string {
    version = version.toUpperCase();
    client = client.toLowerCase();
    if (client === 'rogers' || client === 'cbu' || client === 'shaw'){
      return version === 'V2' ? environment.rogersV2SiteKey : environment.rogersV3SiteKey;
    }
  if (client === 'fido'){
        return version === 'V2' ? environment.fidoV2SiteKey : environment.fidoV3SiteKey;
      }
      if (client === 'yahoo'){
        return version === 'V2' ? environment.yahooV2SiteKey : environment.yahooV3SiteKey;
      }
       if (client === 'chatr' || client === 'cpp'){
        return version === 'V2' ? environment.chatrV2SiteKey : environment.chatrV3SiteKey;
      }
      if (client === 'r4b'){
        return version === 'V2' ? environment.r4bV2SiteKey : environment.r4bV3SiteKey;
      }
      if (client === 'media'){
        return version === 'V2' ? environment.mediaV2SiteKey : environment.mediaV3SiteKey;
      }
      // if (client === 'rshm' || client === 'ignitetv' || client === 'xfi'){
      //   return version === 'V2' ? environment.chV2SiteKey : environment.chV3SiteKey;
      // }
    return '';
  }
}