import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HeaderState } from './header.state';

@Injectable({ providedIn: 'root' })
export class HeaderStateService {
  private headerStore$: BehaviorSubject<HeaderState> = new BehaviorSubject(new HeaderState());
  private appLogo = new Subject<string>();
  appLogo$ = this.appLogo.asObservable();

  private backToStart = new Subject<boolean>();
  backToStart$ = this.backToStart.asObservable();
  constructor() {
  }

  currentState(): HeaderState {
    return this.headerStore$.getValue();
  }

  setHeaderState(headerState: HeaderState) {
    this.headerStore$.next(headerState);
  }

  get headerState$() {
    return this.headerStore$.asObservable();
  }

  setAppLogo(logoUrl: string) {
    this.appLogo.next(logoUrl);
  }

  setBackToStart(value: boolean) {
    this.backToStart.next(value);
  }
}
