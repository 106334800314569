import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalComponent as DsModalComponent } from '@rogers/cdk/modal';
import { ConfigurationService } from 'src/app/shared/configuration.service';
import { GlobalStateService } from 'src/app/shared/global-state.service';
import { CookieService } from 'ngx-cookie-service';
import { UserSessionRiskService } from 'src/app/shared/user-session-risk-service';

interface FormattedOTPTarget {
  channel: string;
  targetDescription: string;
}

@Component({
  selector: 'app-confirm-change-username',
  templateUrl: './confirm-change-username.component.html',
  styleUrls: ['./confirm-change-username.component.scss']
})

export class ConfirmChangeUsernameComponent implements OnInit {

  confirmUsernameInProgress = false;
  confirmUsernameForm: UntypedFormGroup;
  modalRef: ModalRef;
  @Output() back = new EventEmitter<void>();
  @Input() hideBackBtn: boolean;
  @Input() showLogo = true;
  newUsername: string;
  targetsCount = 0;
  targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>;
  formattedTargets: FormattedOTPTarget[] = [];
  onTargetSelected: (target: com.ts.mobile.sdk.AuthenticatorTarget) => void;
  error = '';
  client: string | null;
  confirmUsernameFormbody: string;
  userName = '';



  track = {
    changeUsernameConfirmPageview: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'change-username-confirm',
    },
    changeUsernameConfirmErrors: {
      pageView: true,
      isModalPopup: false,
      mappingKey: 'change-username-confirm',
      category: 'self serve',
      type: this.error === 'tooManyAttemptsOrExpired' ? 'user' : 'http',
      code: this.error,
      API: this.configurationService.config.analyticsAPIUrl,
      field: ''
    }
  };

  constructor(private readonly configurationService: ConfigurationService,
              private modalService: ModalService,
              private viewContainerRef: ViewContainerRef,
              private readonly translate: TranslateService,
              private readonly globalStateService: GlobalStateService,
              private readonly cookieService: CookieService,
              private readonly userSessionRiskService: UserSessionRiskService) { }


  public setTargets = (targets: Array<com.ts.mobile.sdk.AuthenticatorTarget>) => {
    this.targets = targets;
    this.formatTargets();
}

private formatTargets = (): void => {
  const formatted: FormattedOTPTarget[] = [];
  this.targets.forEach((target: com.ts.mobile.sdk.OtpTarget) => {
      formatted.push({
          channel: this.getChannelName(target.getChannel()),
          targetDescription: target.getDescription()
      });
  });
  this.formattedTargets = formatted;
  this.targetsCount = this.formattedTargets.length;
}

private getChannelName = (channel: com.ts.mobile.sdk.OtpChannel): string => {
  switch (channel) {
      case 1: return 'Sms';
      case 2: return 'Email';
      case 3: return 'PushNotification';
      case 4: return 'VoiceCall';
      default: return 'Unknown';
  }
}

  resetErrorMessage(): void {
    this.error = '';
  }

  ngOnInit(): void {
    this.userName = this.cookieService.get('username');
    this.newUsername = this.cookieService.get('newUsername');
    this.globalStateService.setHideLangToggle(false);
    this.cookieService.delete('username_timer');

    if (!this.cookieService.check('OTPERROR'))
    {
        this.cookieService.set('OTPERROR', '', undefined, undefined, null, true, 'None'); // Initialize
    }

    this.error = this.cookieService.get('OTPERROR');
    this.setTranslatePropertiesByBrand();

  }

  onConfirmSubmit(){
    this.confirmUsernameInProgress = true;
    this.triggerRiskAction('profile-change-username-confirm', this.userName);
    this.onTargetSelected(this.targets[0]);
  }

   onBack() {
    this.back.emit();
  }

  openModal(templateRef: TemplateRef<DsModalComponent>) {
    this.modalRef = this.modalService.open(
      templateRef,
      this.viewContainerRef,
      { backdropClick: false }
    );
  }

  closeModal() {
    this.modalRef.close();
  }

  private setTranslatePropertiesByBrand(){
    this.client = this.cookieService.get('client');

    // Overrides below
    if (this.client === 'media'){
      this.confirmUsernameFormbody = 'media.confirmUsernameForm.body';
    }
    else if (this.client === 'fido') {
      this.confirmUsernameFormbody = 'fido.confirmUsernameForm.body';
    }
    else if (this.client === 'r4b') {
      this.confirmUsernameFormbody = 'r4b.confirmUsernameForm.body';
    }
    else if (this.client === 'yahoo') {
      this.confirmUsernameFormbody = 'yahoo.confirmUsernameForm.body';
    }
    else if (this.client === 'chatr') {
      this.confirmUsernameFormbody = 'chatr.confirmUsernameForm.body';
    }
    else {
      this.confirmUsernameFormbody = 'rogers.changeUsername.confirmUsernameForm.body';
    }
  }


  get isLogoLinked() {
    return false;
  }

  get homeLinkUrl() {
    const homeURL = this.configurationService.config.homeURL;
    return homeURL + `?language=${this.translate.currentLang}`;
  }

  get urlTarget() {
    return '_self';
  }

  async triggerRiskAction(flow: string, userId: string) {
    await this.userSessionRiskService.triggerAction(flow, userId);
  }

}
