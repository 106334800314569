<h1 class="text-title-4 mb-8" translate>rogers.setPassword.recovery.changePassword</h1>

  <!-- Set Password Form  -->
<form
  [formGroup]="setPasswordForm"
  novalidate
  (ngSubmit) ="onSetPasswordSubmit()"
  autocomplete="off"
  method="POST"
  #setNewPassswordRef
  preventSubmitDirective
>
  <ng-container ssTrackEvent [event]="track.changePasswordConfirmPageview"></ng-container>
  <div style="position: absolute; left: -9999px;">
    <ds-form-field>
      <ds-input-label>{{ 'common.username' | translate }}</ds-input-label>
      <input
        type="email"
        dsInput
        id="username"
        name="username"
        autocomplete="username"
        formControlName="userName"
      />
    </ds-form-field>
  </div>
  <div class="mb-24">
    <ds-alert
      variant="error"
      [closeLabel]="'rogers.login.popOverClose' | translate"

      class="mb-16"
      *ngIf="!!apiErrorTranslationKey"
      role="alert"
      ssTrackError
      [error]="track.changePasswordConfirmErrors"
    >
      <div class="d-flex flex-column flex-md-row">
        <div class="flex-grow-1 d-flex">
          <ds-icon
            class="mr-8"
            name="error-outlined"
            color="error"
            [ariaHidden]="false"
            caption="Attention"
            [attr.title]="'rogers.login.accessibility.error' | translate"
          ></ds-icon>
          <p damTranslationWithRouting
             class="text-body mb-0 mw-content"
             [innerHTML]="apiErrorTranslationKey | translate">
          </p>
        </div>
      </div>
    </ds-alert>
    <ds-form-field>
      <ds-input-label>{{
        'rogers.setPassword.' + 'recovery' + '.newPassword' | translate
      }}</ds-input-label>
      <input
        #newPasswordRef
        dsInput
        type="password"
        formControlName="newPassword"
        autocomplete="new-password"
        (focus)="this.hidePasswordTip = false"
        class="round-input"
        [attr.autocorrect]="'off'"
        maxlength="64"
        spellcheck="false"
        autocapitalize="off"
        autofill
        id="newPassword"
        name="newPassword"
        required
        (keydown)="checkOnKeyChange($event, 'new')"
        (keyup)="checkOnKeyChange($event, 'new')"
        [showPasswordToggleButton]="false"
        [attr.type]="hideNewPassword ? 'password' : 'text'"
        [attr.aria-required]="true"
        [attr.aria-invalid]="false"
        [attr.aria-label]="
          'rogers.setPassword.' + 'recovery' + '.accessibility.setNewPassword'
            | translate
        "
        [attr.title]="
          'rogers.setPassword.' + 'recovery' + '.accessibility.setNewPassword'
            | translate
        "
      />
      <button
        dsSuffix
        ds-button
        type="button"
        variant="icon"
        (click)="hideNewPassword = !hideNewPassword"
        [attr.title]="
          hideNewPassword
            ? ('rogers.setPassword.' +
            'recovery' +
                '.accessibility.showNewPassword' | translate)
            : ('rogers.setPassword.' +
            'recovery' +
                '.accessibility.hideNewPassword' | translate)
        "
      >
        <ds-icon [name]="hideNewPassword ? 'hide' : 'show'"></ds-icon>
      </button>
      <ds-password-hints [hidden]="hidePasswordTip">
        <ds-password-hint-item
          *ngFor="let hint of newPasswordHints"
          [type]="hint.type"
          [valid]="hint.valid"
          [icon]="hint.icon"
          message="{{ hint.message | translate }}"
          [isVisible]="hint.isVisible"
        ></ds-password-hint-item>
      </ds-password-hints>
      <!-- <ds-error
        errorMessage="{{
          'rogers.setPassword.message.toContinueErrorCheckAbove' | translate
        }}"
        role="alert"
      >
      </ds-error> -->
      <ds-error *ngIf="isNewPassordValid"
      errorMessage="{{
        'rogers.setPassword.message.toContinueErrorCheckAbove' | translate
      }}"
      role="alert"
      [attr.title]="
      'rogers.setPassword.' + 'recovery' + '.accessibility.error'
        | translate
    ">
    </ds-error>

    <ds-error *ngIf="!isNewPassordValid"
    errorMessage="{{
      'rogers.setPassword.message.toContinueErrorCheck' | translate
    }}"
    role="alert"
    [attr.title]="
    'rogers.setPassword.' + 'recovery' + '.accessibility.error'
      | translate
  ">
  </ds-error>
    </ds-form-field>
  </div>
  <div>
    <ds-form-field>
      <ds-input-label>{{
        'rogers.setPassword.' + 'recovery' + '.confirmPassword' | translate
      }}</ds-input-label>
      <input
        (focus)="this.hideConfirmPasswordTip = false"
        type="password"
        dsInput
        id="confirmPassword"
        name="confirm password"
        class="round-input"
        formControlName="confirmPassword"
        maxlength="64"
        autocapitalize="off"
        spellcheck="false"
        [attr.autocorrect]="'off'"
        [showPasswordToggleButton]="false"
        [attr.type]="hideConfirmPassword ? 'password' : 'text'"
        required
        (keydown)="checkOnKeyChange($event, 'confirm')"
        (keyup)="checkOnKeyChange($event, 'confirm')"
        [attr.aria-required]="true"
        [attr.aria-invalid]="false"
        [attr.aria-label]="
          'rogers.setPassword.' + 'recovery' + '.accessibility.setNewConfirmPassword'
            | translate
        "
        [attr.title]="
          'rogers.setPassword.' + 'recovery' + '.accessibility.setNewConfirmPassword'
            | translate
        "
      />
      <button
        dsSuffix
        ds-button
        type="button"
        variant="icon"
        (click)="hideConfirmPassword = !hideConfirmPassword"
        [attr.title]="
          hideConfirmPassword
            ? ('rogers.setPassword.' +
            'recovery' +
                '.accessibility.showNewPassword' | translate)
            : ('rogers.setPassword.' +
            'recovery' +
                '.accessibility.hideNewPassword' | translate)
        "
      >
        <ds-icon [name]="hideConfirmPassword ? 'hide' : 'show'"></ds-icon>
      </button>
      <ds-password-hints [hidden]="hideConfirmPasswordTip">
        <ds-password-hint-item
          *ngFor="let hint of confirmHints"
          [type]="hint.type"
          [valid]="hint.valid"
          [icon]="hint.icon"
          message="{{ hint.message | translate }}"
          [isVisible]="hint.isVisible"
        ></ds-password-hint-item>
      </ds-password-hints>
      <!-- <ds-error
        errorMessage="{{
          'rogers.setPassword.message.passwordsMustMatch' | translate
        }}"
        role="alert"
      ></ds-error> -->
      <ds-error *ngIf = "blankPasswordSubmit"
      errorMessage="{{
        'rogers.setPassword.message.blankPassword' | translate
      }}"
      role="alert"
      ssTrackError
      [error]="track.changePasswordConfirmFormError"
      [attr.title]="
      'rogers.setPassword.' + 'recovery' + '.accessibility.error'
        | translate
    ">
      </ds-error>
    <ds-error *ngIf = "!blankPasswordSubmit"
    errorMessage="{{
      'rogers.setPassword.message.passwordsMustMatch' | translate
    }}"
    role="alert"
    ssTrackError
    [error]="track.changePasswordConfirmFormError"
    [attr.title]="
    'rogers.setPassword.' + 'recovery' + '.accessibility.error'
      | translate
  "></ds-error>
    </ds-form-field>
  </div>
  <div class="mb-32 "></div>

 <div class="d-flex w-50">
  <button
    ds-button
    role="button"
    class="w-100 mb-16"
    type="submit"
    #continueBtn
    title="{{ 'rogers.setPassword.recovery.changePasswordSubmit' | translate }}">
    <span *ngIf="!callInProgress">{{'common.submit' | translate}}</span>
    <eas-loader class="eas-loader" *ngIf="callInProgress"></eas-loader>
  </button>

  <button ds-button [variant]="'tertiary'" style="margin-left: 20px;"
  title="{{ 'rogers.setPassword.recovery.changePasswordCancel' | translate }}"
  (click)="onChangePasswordCancel($event)"> {{ 'common.cancel' | translate }}
 </button>

</div>

</form>
